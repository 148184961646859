import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAffiliateDashboard = createAsyncThunk(
    "getAffiliateDashboard",
    async ({ url }) => {
      try {
        const response = await axios.get(url, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });
  
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  );

export const getAllTransition = createAsyncThunk(
    "getAllTransition",
    async ({ url }) => {
      try {
        const response = await axios.get(url, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });
  
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  );

export const createAffiliateProduct = createAsyncThunk(
    "createAffiliateProduct",
    async ({ url,data }) => {
      try {
        const response = await axios.post(url,data, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });
  
        return response;
      } catch (error) {
        return error?.response?.data;
      }
    }
  );

export const createAffiliate = createAsyncThunk(
    "createAffiliate",
    async ({ url,data }) => {
      try {
        const response = await axios.post(url,data, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });
  
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  );

  export const getAffiliateByUserId = createAsyncThunk(
    "getAffiliateByUserId",
    async ({ url }) => {
      try {
        const response = await axios.get(url, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });
  
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  );

  export const withDrawAmount = createAsyncThunk(
    "withDrawAmount",
    async ({ url,data }) => {
      try {
        const response = await axios.post(url,data, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        });
  
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  );

const affiliateSlice = createSlice({
  name: "affiliate",
  initialState: {
    loader: false,
    getDashboard: null,
    getAffilateUser:null,
    affiliateLink:null,
    transition:null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAffiliateDashboard.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAffiliateDashboard.fulfilled, (state, action) => {
        state.getDashboard = action.payload?.data;
        state.loader = false;
      })
      .addCase(getAffiliateDashboard.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getAffiliateByUserId.fulfilled, (state, action) => {
        state.getAffilateUser = action.payload?.data;
        state.loader = false;
      })
      .addCase(createAffiliateProduct.fulfilled, (state, action) => {
        state.affiliateLink = action.payload?.data;
        state.loader = false;
      })
      .addCase(getAllTransition.fulfilled, (state, action) => {
        state.transition = action.payload?.data;
        state.loader = false;
      })
  },
});

export default affiliateSlice.reducer;
