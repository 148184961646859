import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getMembershipImage = createAsyncThunk(
  "getMembershipImage",
  async ({ url }) => {
    try {
      const data = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return data ? data?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

export const getAllMembership = createAsyncThunk(
  "getAllMembership",
  async ({ url }) => {
    try {
      const data = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return data ? data?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

export const purchaseMembership = createAsyncThunk(
  "purchaseMembership",
  async (url) => {
    try {
      const data = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return data ? data?.data : null;
    } catch (error) {
      // console.log(error);
      return null;
    }
  }
);

const memberSlice = createSlice({
  name: "memberShip",
  initialState: {
    loader: false,
    allmemebership: null,
    memberShipPur: null,
    memberShipImage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMembership.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllMembership.fulfilled, (state, action) => {
        state.allmemebership = action.payload?.data;
        state.loader = false;
      })
      .addCase(getAllMembership.rejected, (state) => {
        state.loader = false;
      })
      .addCase(purchaseMembership.pending, (state) => {
        state.loader = true;
      })
      .addCase(purchaseMembership.fulfilled, (state, action) => {
        state.memberShipPur = action.payload;
        state.loader = false;
      })
      .addCase(purchaseMembership.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getMembershipImage.fulfilled, (state, action) => {
        state.memberShipImage = action.payload?.data;
      });
  },
});

export default memberSlice.reducer;
