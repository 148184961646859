import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllCategorySequence = createAsyncThunk(
  "getAllCategorySequence",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      // console.log(error, "Something Went Wrong !");
      return null;
    }
  }
);
export const getNavbarTitle = createAsyncThunk(
  "getNavbarTitle",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      // console.log(error, "Something Went Wrong !");
      return null;
    }
  }
);

export const getParentCategories = createAsyncThunk(
  "getParentCategories",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      // console.log(error, "Something Went Wrong !");
      return null;
    }
  }
);
export const getSubCategories = createAsyncThunk(
  "getSubCategories",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      // console.log(error, "Something Went Wrong !");
      return null;
    }
  }
);

export const getChildCategories = createAsyncThunk(
  "getChildCategories",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      // console.log(error, "Something Went Wrong !");
      return null;
    }
  }
);

const navbarSlice = createSlice({
  name: "navbar",
  initialState: {
    loader: false,
    isLoading: false,
    isLoading2: false,
    isLoading3: false,
    navbarTitle: null,
    parentTitle: null,
    subTitle: null,
    childTitle: null,
    allSequence:null,
  },
  reducers: {
    handleChild: (state, action) => {
      state.childTitle = action.payload;
    },
    handleSubCategory: (state, action) => {
      state.subTitle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNavbarTitle.pending, (state) => {
        state.loader = true;
      })
      .addCase(getNavbarTitle.fulfilled, (state, action) => {
        state.navbarTitle = action.payload?.data;
        state.loader = false;
      })
      .addCase(getNavbarTitle.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getSubCategories.pending, (state) => {
        state.isLoading2 = true;
      })
      .addCase(getSubCategories.fulfilled, (state, action) => {
        state.subTitle = action.payload?.data || [];
        state.isLoading2 = false;
      })
      .addCase(getSubCategories.rejected, (state) => {
        state.isLoading2 = false;
      })
      .addCase(getChildCategories.pending, (state) => {
        state.isLoading3 = true;
      })
      .addCase(getChildCategories.fulfilled, (state, action) => {
        state.childTitle = action.payload?.data || [];
        state.isLoading3 = false;
      })
      .addCase(getChildCategories.rejected, (state) => {
        state.isLoading3 = false;
      })
      .addCase(getParentCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParentCategories.fulfilled, (state, action) => {
        state.parentTitle = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(getParentCategories.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllCategorySequence.fulfilled, (state, action) => {
        state.allSequence = action.payload?.data;
        state.isLoading = false;
      })
  },
});
export const { handleChild,handleSubCategory } = navbarSlice.actions;
export default navbarSlice.reducer;
