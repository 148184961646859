import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { closeModal, openOtpModal } from "../../Redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { sendOtp, userRegister } from "../../Redux/features/userSlice";
import { handleAlert } from "../../Redux/alertSlice";
import CloseIcon from "@mui/icons-material/Close";

const LoginModal = () => {
  const { isOpen } = useSelector((state) => state?.isLogin);
  const { loader } = useSelector((state) => state?.isUser);
  const dispatch = useDispatch();
  const [sign, setSign] = useState(false);
  const dummyUserId = localStorage.getItem("_id");

  // imput data
  const [inputData, setInputData] = useState({
    hashKey: `${process.env.REACT_APP_HASHKEY}`,
    // fcmToken:localStorage.getItem("fireToken"),
    phoneNumber: "",
    fullName: "",
  });

  // handle form for inputdata
  const handleForm = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      setInputData((prev) => ({
        ...prev,
        phoneNumber: isNaN(parseInt(value[value.length - 1]))
          ? value.length === 1
            ? ""
            : value.slice(0, value.length - 1)
          : value,
      }));
    } else {
      setInputData((prev) => ({
        ...prev,
        fullName: value.replace(/[^a-z ]/gi, ""),
      }));
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(
      userRegister({
        url: `${process.env.REACT_APP_API}/login?dummyUserId=${
          dummyUserId ? dummyUserId : ""
        }`,
        data: {
          hashKey: `${process.env.REACT_APP_HASHKEY}`,
          phoneNumber: inputData?.phoneNumber,
          // fcmToken:localStorage.getItem("fireToken")
        },
      })
    ).then((res) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: res?.payload?.success ? "success" : "error",
          msg: res?.payload?.message || res?.payload?.data?.message,
        })
      );
      if (res?.payload?.success) {
        dispatch(
          sendOtp({
            url: `${process.env.REACT_APP_API}/sendOtp`,
            data: { phoneNumber: inputData?.phoneNumber, check: true },
          })
        ).then((res) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: res?.payload?.success ? "success" : "error",
              msg: res?.payload?.message,
            })
          );
          setInputData({
            ...inputData,
            fullName: "",
            phoneNumber: "",
          });
          if (res?.payload?.success) {
            dispatch(closeModal());
            dispatch(openOtpModal());
          }
        });
      } else {
        setSign(true);
        setInputData({
          ...inputData,
          fullName: "",
        });
      }
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(
      userRegister({
        url: `${process.env.REACT_APP_API}/register?dummyUserId=${
          dummyUserId ? dummyUserId : ""
        }`,
        data: inputData,
      })
    ).then((res) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: res?.payload?.success ? "success" : "error",
          msg: res?.payload?.message || res?.payload?.data?.message,
        })
      );
      if (res?.payload?.success) {
        dispatch(
          sendOtp({
            url: `${process.env.REACT_APP_API}/sendOtp`,
            data: { phoneNumber: inputData?.phoneNumber, check: true },
          })
        ).then((res) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: res?.payload?.success ? "success" : "error",
              msg: res?.payload?.message,
            })
          );
          setSign(!sign);
          setInputData({
            ...inputData,
            fullName: "",
            phoneNumber: "",
          });
          if (res?.payload?.success) {
            dispatch(closeModal());
            dispatch(openOtpModal());
          }
        });
      } else {
        setSign(false);
      }
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        // onClose={() => dispatch(closeModal())}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { md: "30rem", xs: "80vw" },
              height: { md: "auto", xs: "auto" },
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 2,
              px: 4,
            }}
          >
            <Box
              gap={4}
              sx={{
                py: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  border: 2,
                  borderRadius: 1,
                  p: 0.2,
                  color: "secondary.main",
                }}
                onClick={() => dispatch(closeModal())}
              >
                <CloseIcon
                  sx={{
                    color: "secondary.main",
                  }}
                />
              </IconButton>
              {sign && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 5,
                    left: 5,
                    border: 2,
                    borderRadius: 1,
                    p: 0.2,
                    color: "secondary.main",
                  }}
                  onClick={() => {
                    setSign(!sign);
                    setInputData({
                      ...inputData,
                      phoneNumber: "",
                    });
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              {/* For login */}

              {!sign && (
                <>
                  <Typography
                    variant="h1"
                    sx={{
                      // ml: 5,
                      fontSize: {
                        md: "1.8rem",
                        xs: "1.3rem",
                        textAlign: "center",
                      },
                      fontWeight: 700,
                    }}
                  >
                    Register/Sign In
                  </Typography>
                  <form
                    onSubmit={handleLogin}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "secondary.main",
                          fontSize: "1.2rem",
                          mb: 0.5,
                        }}
                      >
                        Phone
                      </Typography>
                      <TextField
                        size="small"
                        id="outlined-start-adornment"
                        placeholder="Phone Number"
                        autoComplete="off"
                        name="phoneNumber"
                        type="text"
                        required
                        value={inputData?.phoneNumber}
                        onChange={handleForm}
                        sx={{ mb: 2, width: "100%" }}
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                      />
                    </Box>
                    <LoadingButton
                      sx={{
                        height: "2.8rem",
                        backgroundColor: "secondary.main",
                        minWidth: "30%",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        color: "white.main",
                        "&:hover": {
                          color: "white.main",
                          backgroundColor: "secondary.main",
                        },
                      }}
                      loading={loader}
                      loadingPosition="center"
                      type="submit"
                      variant="contained"
                    >
                      Get OTP
                    </LoadingButton>
                  </form>
                </>
              )}

              {/* For registration */}

              {sign && (
                <>
                  <Typography
                    sx={{
                      ml: 5,
                      fontSize: { md: "1.8rem", xs: "1.3rem" },
                      fontWeight: 600,
                    }}
                  >
                    Registration
                  </Typography>
                  <form
                    onSubmit={handleRegister}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "80%" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "secondary.main",
                          fontSize: "1.2rem",
                        }}
                      >
                        Full Name
                      </Typography>
                      <TextField
                        size="small"
                        id="outlined-start-adornment"
                        autoComplete="off"
                        placeholder="Full Name"
                        name="fullName"
                        required
                        value={inputData?.fullName}
                        onChange={handleForm}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "secondary.main",
                          fontSize: "1.2rem",
                          mt: 1,
                        }}
                      >
                        Phone
                      </Typography>
                      <TextField
                        size="small"
                        id="outlined-start-adornment"
                        autoComplete="off"
                        placeholder="Phone Number"
                        required
                        name="phoneNumber"
                        type="text"
                        value={inputData?.phoneNumber}
                        onChange={handleForm}
                        sx={{ width: "100%" }}
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                      />
                    </Box>
                    <LoadingButton
                      sx={{
                        height: "2.8rem",
                        backgroundColor: "secondary.main",
                        minWidth: "30%",
                        borderRadius: "10px",
                        mt: 2,
                        textTransform: "capitalize",
                        color: "white.main",
                        "&:hover": {
                          color: "white.main",
                          backgroundColor: "secondary.main",
                        },
                      }}
                      loading={loader}
                      loadingPosition="center"
                      type="submit"
                      variant="contained"
                    >
                      Get OTP
                    </LoadingButton>
                  </form>
                </>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default LoginModal;
