import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createContact = createAsyncThunk(
  "createContact",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    loader: false,
    contact: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createContact.pending, (state) => {
        state.loader = true;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.contact = action.payload?.data;
        state.loader = false;
      })
      .addCase(createContact.rejected, (state) => {
        state.userData = null;
        state.loader = false;
      });
  },
});

export default contactSlice.reducer;
