import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSingleProduct = createAsyncThunk(
  "getSingleProduct",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

export const getAllProduct = createAsyncThunk(
  "getAllProduct",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

export const getRelatedProduct = createAsyncThunk(
  "getRelatedProduct",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    singleProduct: null,
    AllProduct: null,
    relatedProduct: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        state.singleProduct = action.payload?.data;
        state.loading = false;
      })
      .addCase(getSingleProduct.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllProduct.fulfilled, (state, action) => {
        state.AllProduct = action.payload?.data;
        state.loading = false;
      })
      .addCase(getRelatedProduct.fulfilled, (state, action) => {
        state.relatedProduct = action.payload?.data;
        state.loading = false;
      });
  },
});

export default productSlice.reducer;
