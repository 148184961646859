import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getHomeData = createAsyncThunk(
  "getHomeData",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
          // "Access-Control-Allow-Origin": "*",
          // "Content-Type": "application/json",
        },
      });

      return response?.data;
    } catch (error) {
      // console.log(error)
      // return error?.response?.data;
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState: {
    loading: false,
    getHome: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getHomeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeData.fulfilled, (state, action) => {
        state.loading = false;
        state.getHome = action?.payload?.data;
      })
      .addCase(getHomeData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default homeSlice.reducer;
