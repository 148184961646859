import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createOrder = createAsyncThunk(
  "createOrder",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getOrderByUserId = createAsyncThunk(
  "getOrderByUserId",
  async ({ url }) => {
    try {
      const data = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return data ? data?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const getOrderByOrderId = createAsyncThunk(
  "getOrderByOrderId",
  async ({ url }) => {
    try {
      const data = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return data ? data?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const updateOrder = createAsyncThunk(
  "updateOrder",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

export const cancelOrder = createAsyncThunk("cancelOrder", async ({ url }) => {
  try {
    const response = await axios.put(url, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return response ? response : null;
  } catch (error) {
    console.log(error?.response?.data);
    return error?.response?.data;
  }
});

const orderSlice = createSlice({
  name: "order",
  initialState: {
    getAllOrders: null,
    getOrder: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderByUserId.pending, (state) => {
        state.loader = true;
      })
      .addCase(getOrderByUserId.fulfilled, (state, action) => {
        state.getAllOrders = action.payload?.data;
        state.loader = false;
      })
      .addCase(getOrderByUserId.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getOrderByOrderId.pending, (state) => {
        state.loader = true;
      })
      .addCase(getOrderByOrderId.fulfilled, (state, action) => {
        state.getOrder = action.payload?.data;
        state.loader = false;
      })
      .addCase(getOrderByOrderId.rejected, (state) => {
        state.loader = false;
      })
  },
});

export default orderSlice.reducer;
