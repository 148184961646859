// import HomePage from "@pages/HomePage";
// import BlogPage from "@pages/BlogPage";
// import ExplorePage from "@pages/ExplorePage";
// import TermAndConditionPage from "@pages/TermAndConditionPage";
// import ReturnPolicyPage from "@pages/ReturnPolicyPage";
// import SupportPolicyPage from "@pages/SupportPolicyPage";
// import PrivacyPolicyPage from "@pages/PrivacyPolicyPage";
// import ProductFilterPage from "@pages/ProductFilterPage";
// import ProductDetailPage from "@pages/ProductDetailPage";
// import DashboardSidebar from "@component/DashboardSidebar";
// import SkinCare from "@pages/SkinCare";
// import SelfAssessment from "@pages/SelfAssessment";
// import ConsultExpert from "@pages/ConsultExpert";
// import ContactUs from "@pages/ContactUs";
// import BlogDetailPage from "@pages/BlogDetailPage";
// import PageNotFound from "@pages/PageNotFound";
// import CartCheckOutPage from "@pages/CartCheckOutPage";
// import TakeSelfAssessmentPage from "@pages/TakeSelfAssessmentPage";
// import TakeSelfAssessmentForm from "@pages/TakeSelfAssessmentForm";
// import ResultPage from "@pages/ResultPage";
// import DoctorFormPage from "./Pages/DoctorFormPage";
// import ConsultDoctorConfirmPage from "./Pages/ConsultDoctorConfirmPage";
// import AboutUsPage from "./Pages/AboutUs";
// import MobileProfilePage from "./Pages/MobileProfilePage";
// import ProfileOrder from "./Components/ProfileDashboard/ProfileOrder";
// import ProfileFavourite from "./Components/ProfileDashboard/ProfileFavourite";
// import ProfileAddress from "./Components/ProfileDashboard/ProfileAddress";
// import AffiliateSystem from "./Components/ProfileDashboard/AffiliateSystem";
// import CheckoutOutBuyNowPage from "./Pages/CartCheckOutPage/CheckoutOutBuyNowPage";
// import MobileOrderDetails from "./Pages/MobileOrder/MobileOrderDetails";
// import MobileNotifiation from "./Pages/MobileNotification";
// import MobileOffers from "./Pages/MobileOffers";
// import MobileAffiliate from "./Pages/MobileAffiliatePage";
// import PaymentHistory from "./Pages/MobileAffiliatePage/PaymentHistory";
// import CreateMobileAffiliate from "./Pages/MobileAffiliatePage/CreateMobileAffiliate";
// import MobileMemberShip from "./Pages/MobileMemberShip";
// import Wallet from "./Pages/Wallet";
// import AssessmentQuestion from "./Pages/AssessmentQuestion";
// import AssessmentResult from "./Pages/AssessmentResult";
// import MobileWalletWithdraw from "./Pages/MobileWalletWithdraw/index";
// import Diseases from "./Pages/Diseases";
// import DoctorMeetTime from "./Pages/DoctorMeetTime";
// import AccountDelete from "./Pages/AccountDelete";
// import Consultation from "./Pages/Consultation";
// import Partnership from "./Pages/Partnership";
// import PartnerRegistration from "./Pages/PartnerRegistration";

import { lazy } from 'react';

const HomePage = lazy(() => import("@pages/HomePage"));
const BlogPage = lazy(() => import("@pages/BlogPage"));
const ExplorePage = lazy(() => import("@pages/ExplorePage"));
const TermAndConditionPage = lazy(() => import("@pages/TermAndConditionPage"));
const ReturnPolicyPage = lazy(() => import("@pages/ReturnPolicyPage"));
const SupportPolicyPage = lazy(() => import("@pages/SupportPolicyPage"));
const PrivacyPolicyPage = lazy(() => import("@pages/PrivacyPolicyPage"));
const ProductFilterPage = lazy(() => import("@pages/ProductFilterPage"));
const ProductDetailPage = lazy(() => import("@pages/ProductDetailPage"));
const DashboardSidebar = lazy(() => import("@component/DashboardSidebar"));
const SkinCare = lazy(() => import("@pages/SkinCare"));
const SelfAssessment = lazy(() => import("@pages/SelfAssessment"));
const ConsultExpert = lazy(() => import("@pages/ConsultExpert"));
const ContactUs = lazy(() => import("@pages/ContactUs"));
const BlogDetailPage = lazy(() => import("@pages/BlogDetailPage"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));
const CartCheckOutPage = lazy(() => import("@pages/CartCheckOutPage"));
const TakeSelfAssessmentPage = lazy(() => import("@pages/TakeSelfAssessmentPage"));
const TakeSelfAssessmentForm = lazy(() => import("@pages/TakeSelfAssessmentForm"));
const ResultPage = lazy(() => import("@pages/ResultPage"));
const DoctorFormPage = lazy(() => import("./Pages/DoctorFormPage"));
const ConsultDoctorConfirmPage = lazy(() => import("./Pages/ConsultDoctorConfirmPage"));
const AboutUsPage = lazy(() => import("./Pages/AboutUs"));
const MobileProfilePage = lazy(() => import("./Pages/MobileProfilePage"));
const ProfileOrder = lazy(() => import("./Components/ProfileDashboard/ProfileOrder"));
const ProfileFavourite = lazy(() => import("./Components/ProfileDashboard/ProfileFavourite"));
const ProfileAddress = lazy(() => import("./Components/ProfileDashboard/ProfileAddress"));
const AffiliateSystem = lazy(() => import("./Components/ProfileDashboard/AffiliateSystem"));
const CheckoutOutBuyNowPage = lazy(() => import("./Pages/CartCheckOutPage/CheckoutOutBuyNowPage"));
const MobileOrderDetails = lazy(() => import("./Pages/MobileOrder/MobileOrderDetails"));
const MobileNotifiation = lazy(() => import("./Pages/MobileNotification"));
const MobileOffers = lazy(() => import("./Pages/MobileOffers"));
const MobileAffiliate = lazy(() => import("./Pages/MobileAffiliatePage"));
const PaymentHistory = lazy(() => import("./Pages/MobileAffiliatePage/PaymentHistory"));
const CreateMobileAffiliate = lazy(() => import("./Pages/MobileAffiliatePage/CreateMobileAffiliate"));
const MobileMemberShip = lazy(() => import("./Pages/MobileMemberShip"));
const Wallet = lazy(() => import("./Pages/Wallet"));
const AssessmentQuestion = lazy(() => import("./Pages/AssessmentQuestion"));
const AssessmentResult = lazy(() => import("./Pages/AssessmentResult"));
const MobileWalletWithdraw = lazy(() => import("./Pages/MobileWalletWithdraw/index"));
const Diseases = lazy(() => import("./Pages/Diseases"));
const DoctorMeetTime = lazy(() => import("./Pages/DoctorMeetTime"));
const AccountDelete = lazy(() => import("./Pages/AccountDelete"));
const Consultation = lazy(() => import("./Pages/Consultation"));
const Partnership = lazy(() => import("./Pages/Partnership"));
const PartnerRegistration = lazy(() => import("./Pages/PartnerRegistration"));

export const routes = [
  {
    path: "/",
    component: <HomePage />,
    title: "Home",
  },
  {
    path: "*",
    component: <PageNotFound />,
    title: "Page Not Found",
  },
  {
    path: "/explore",
    component: <ExplorePage />,
    title: "Explore",
  },
  {
    path: "/blog",
    component: <BlogPage />,
    title: "Blogs",
  },
  {
    path: "/blogDetail/:id",
    component: <BlogDetailPage />,
    title: "Blog Details",
  },
  {
    path: "/term&condition",
    component: <TermAndConditionPage />,
    title: "Terms and Conditions",
  },
  {
    path: "/returnPolicy",
    component: <ReturnPolicyPage />,
    title: "Return Policy",
  },
  {
    path: "/supportPolicy",
    component: <SupportPolicyPage />,
    title: "Support Policy",
  },
  {
    path: "/privacyPolicy",
    component: <PrivacyPolicyPage />,
    title: "Privacy Policy",
  },
  {
    path: "/aboutUs",
    component: <AboutUsPage />,
    title: "About Us",
  },
  {
    path: "/filter/:features",
    component: <ProductFilterPage />,
    title: "Filter Page",
  },
  {
    path: "/productDetail/:id",
    component: <ProductDetailPage />,
    title: "Product Details",
  },
  {
    path: "/profileDashboard",
    component: <DashboardSidebar />,
    title: "Edit Profile",
    protected: true,
  },
  {
    path: "/skinCare",
    component: <SkinCare />,
    title: "Skin Care",
  },
  {
    path: "/selfAssessment",
    component: <SelfAssessment />,
    title: "Self Assessment",
  },
  {
    path: "/consultExpert",
    component: <ConsultExpert />,
    title: "Consult Expert",
  },
  {
    path: "/contactUs",
    component: <ContactUs />,
    title: "Contact Us",
  },
  {
    path: "/cartCheckOutPage",
    component: <CartCheckOutPage />,
    title: "Cart Checkout",
  },
  {
    path: "/CheckOutBuyNow",
    component: <CheckoutOutBuyNowPage />,
    title: "Buy Now Checkout",
  },
  {
    path: "/takeSelfAssessment",
    component: <TakeSelfAssessmentPage />,
    title: "Self Assessment",
  },
  {
    path: "/selfForm",
    component: <TakeSelfAssessmentForm />,
    title: "Self Assessment Form",
  },
  {
    path: "/assessResult",
    component: <ResultPage />,
    title: "Result",
  },
  {
    path: "/doctorFormPage",
    component: <DoctorFormPage />,
    title: "Doctor Form",
  },
  {
    path: "/ConsultDoctorConfirm",
    component: <ConsultDoctorConfirmPage />,
    title: "Consult Confirm",
  },
  {
    path: "/MobileProfilePage",
    component: <MobileProfilePage />,
    title: "Profile",
  },
  {
    path: "/ProfileOrder",
    component: <ProfileOrder />,
    title: "Order",
  },
  {
    path: "/ProfileFavourite",
    component: <ProfileFavourite />,
    title: "Wishlist",
  },
  {
    path: "/ProfileAddress",
    component: <ProfileAddress />,
    title: "Address",
  },
  {
    path: "/AffiliateSystem",
    component: <AffiliateSystem />,
    title: "Affiliate System",
  },
  {
    path: "/mobileOrderDetails",
    component: <MobileOrderDetails />,
    title: "Order Details",
  },
  {
    path: "/mobileNotification",
    component: <MobileNotifiation />,
    title: "Notifications",
  },
  {
    path: "/mobileOffers",
    component: <MobileOffers />,
    title: "Offers",
  },
  {
    path: "/mobileAffiliate",
    component: <MobileAffiliate />,
    title: "Affiliate",
  },
  {
    path: "/membership",
    component: <MobileMemberShip />,
    title: "MemberShip",
  },
  {
    path: "/createAffiliate",
    component: <CreateMobileAffiliate />,
    title: "Affiliate Process",
  },
  {
    path: "/mobilePaymentHistory",
    component: <PaymentHistory />,
    title: "Payment History",
  },
  {
    path: "/wallet",
    component: <Wallet />,
    title: "Wallet",
  },
  {
    path: "/AssessmentQuestion",
    component: <AssessmentQuestion />,
    title: "Assessment Q & A",
  },
  {
    path: "/AssessmentResult",
    component: <AssessmentResult />,
    title: "Result",
  },
  {
    path: "/MobileWalletWithdraw",
    component: <MobileWalletWithdraw />,
    title: "Withdraw",
  },
  {
    path: "/Diseases",
    component: <Diseases />,
    title: "Diseases",
  },
  {
    path: "/DoctorMeetDate",
    component: <DoctorMeetTime />,
    title: "Diseases",
  },
  {
    path: "/accountdelete",
    component: <AccountDelete />,
    title: "Account Delete",
  },
  {
    path: "/consultation",
    component: <Consultation />,
    title: "Consultation",
  },
  {
    path: "/partnership",
    component: <Partnership />,
    title: "Partnership",
  },
  {
    path: "/partnerRegistration",
    component: <PartnerRegistration />,
    title: "Partner Registration",
  },
];
