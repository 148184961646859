import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const postAppointment = createAsyncThunk(
  "postAppointment",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    Loading: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(postAppointment.pending, (state) => {
        state.Loading = true;
      })
      .addCase(postAppointment.fulfilled, (state) => {
        // state.blogsData = action?.payload?.data;
        state.Loading = false;
      })
      .addCase(postAppointment.rejected, (state) => {
        state.Loading = false;
      });
  },
});

export default appointmentSlice.reducer;
