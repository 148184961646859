import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: {
    alerts: {
      isOpen: false,
      type: "success",
      msg: "i am shubham",
    },
  },
  reducers: {
    handleAlert: (state, action) => {
      state.alerts = action?.payload;
    },
  },
});

export const { handleAlert } = alertSlice.actions;

export default alertSlice.reducer;
