import { initializeApp } from 'firebase/app';
import { getMessaging ,getToken,onMessage } from "firebase/messaging";

const firebaseConfig  ={
    apiKey: "AIzaSyBYXkd-TWARzgUHWujc9aR0s9k5yTYiKTc",
    authDomain: "well-marts.firebaseapp.com",
    projectId: "well-marts",
    storageBucket: "well-marts.appspot.com",
    messagingSenderId: "454269207755",
    appId: "1:454269207755:web:fdce078bd914930d32474e",
    measurementId: "G-VBC3C7XKW1"
}
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const requestPermission = () => {
    console.log("Requesting User Permission....");
    Notification.requestPermission().then(permission =>{
        if(permission === "granted"){
            console.log("Notification User Permission Granted.");
            return getToken(messaging,{
                vapidKey:"BM85SZrvU-qhLvqPLkmEGXp1Bvl0o0u3OeW1rmwJXdukdJQHC_eyywdce_1LvG-KY--A9s8oiR1SL2eCKF_8ORY"
            }).then(currentToken =>{
                if(currentToken){
                    localStorage.setItem("fireToken",currentToken)
                    console.log("User Permission granted...",);
                    // console.log("Current Token",currentToken);
                    // console.log("Failed to generate the app registration token",currentToken);
                }
            }).catch(err =>{
                console.log("An error occurred when requesting to reduce the token.",err)
            })
        }else{
            console.log("User Permission Denied.");
        }
    })
  };

  export const onMessageListener =()=>
  new Promise(resolve =>{
    onMessage(messaging , payload => {
        resolve(payload)
    })
  })
