import { createSlice } from "@reduxjs/toolkit";

const leftDrawerSlice = createSlice({
  name: "leftDrawerSlice",
  initialState: { state: false },
  reducers: {
    handleOpenDrawerLeft: (state, action) => {
      state.state = action.payload;
    },
  },
});

export const { handleOpenDrawerLeft } = leftDrawerSlice.actions;

export default leftDrawerSlice.reducer;
