import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { closeDummyOtpModal } from "../../Redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { handleAlert } from "../../Redux/alertSlice";
import OTPInput, { ResendOTP } from "otp-input-react";
import CloseIcon from "@mui/icons-material/Close";
import { sendOtp } from "../../Redux/features/userSlice";
import { openPaymentModal } from "../../Redux/loginSlice";

const DummyModal = () => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state?.isUser);
  const [OTP, setOTP] = useState("");
  const mobile = localStorage.getItem("mobile");
  const [input, setInput] = useState({
    phoneNumber: mobile,
    name: "",
  });

  // handle input data
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      setInput((prev) => ({
        ...prev,
        phoneNumber: isNaN(parseInt(value[value.length - 1]))
          ? value.length === 1
            ? ""
            : value.slice(0, value.length - 1)
          : value,
      }));
    } else {
      setInput((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // handle send OTP
  const handleOtp = (e) => {
    e.preventDefault();
    const otp = localStorage.getItem("otp");
    if (otp === OTP) {
      dispatch(
        handleAlert({
          isOpen: true,
          type: "success",
          msg: "OTP Verified",
        })
      );
      dispatch(closeDummyOtpModal());
      dispatch(openPaymentModal());

      localStorage.removeItem("otp");
      setOTP("");
    } else {
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "Invalid OTP",
        })
      );
    }
  };

  // handle resend OTP
  const handleResendOTP = () => {
    dispatch(
      sendOtp({
        url: `${process.env.REACT_APP_API}/sendOtp`,
        data: { phoneNumber: mobile, check: false },
      })
    ).then((res) => {
      if (res?.payload) {
        setOTP("");
        dispatch(
          handleAlert({
            isOpen: true,
            type: "success",
            msg: "Resend OTP",
          })
        );
      }
    });
  };
  const renderTime = (remainingTime) => {
    return (
      <Box sx={{ fontSize: "0.8rem" }}>
        Resend the OTP in{" "}
        {remainingTime !== 0 && (
          <span style={{ color: "red", marginLeft: "5.5rem" }}>
            00:{remainingTime} sec
          </span>
        )}
      </Box>
    );
  };
  const renderButton = (buttonProps) => {
    return (
      <Box sx={{ fontSize: "0.8rem" }} {...buttonProps}>
        {buttonProps.remainingTime !== 0 ? null : (
          <Button
            sx={{
              height: "1rem",
              textTransform: "capitalize",
              color: "secondary.main",
              ml: "4.5rem",
            }}
            variant="text"
            {...buttonProps}
          >
            Resend
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // onClose={() => dispatch(closeOtpModal())}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade
        // in={isDummyOpen}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { md: "30rem", xs: "80vw" },
              height: { md: "auto", xs: "auto" },
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  border: 2,
                  borderRadius: 1,
                  p: 0.2,
                  color: "secondary.main",
                }}
                onClick={() => dispatch(closeDummyOtpModal())}
              >
                <CloseIcon
                  sx={{
                    p: 0.2,
                    color: "secondary.main",
                  }}
                />
              </IconButton>
              <Typography sx={{ fontSize: "1.4rem", fontWeight: 600, mb: 1 }}>
                OTP Verify
              </Typography>

              <form
                onSubmit={handleOtp}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.4,
                    mb: 1.5,
                    width: "95%",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Mobile</Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Your Mobile no"
                    size="small"
                    name="phoneNumber"
                    type="text"
                    required
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                    }}
                    value={input?.phoneNumber}
                    onChange={handleChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.4,
                    mb: 1.5,
                    width: "95%",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Name</Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Your Name"
                    size="small"
                    name="name"
                    type="text"
                    required
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                    }}
                    value={input?.name}
                    onChange={handleChange}
                  />
                </Box>
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  secure
                  inputStyles={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "0.5rem",
                    backgroundColor: "#D9D9D9",
                    border: "none",
                    margin: "0rem 0.6rem",
                  }}
                />
                <ResendOTP
                  style={{
                    margin: "1rem 0rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  maxTime="59"
                  onResendClick={handleResendOTP}
                  renderTime={renderTime}
                  renderButton={renderButton}
                />
                <LoadingButton
                  sx={{
                    height: "2.8rem",
                    backgroundColor: "secondary.main",
                    width: "8rem",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    color: "white.main",
                    "&:hover": {
                      color: "white.main",
                      backgroundColor: "secondary.main",
                    },
                    mt: 1,
                  }}
                  loading={loader}
                  loadingPosition="center"
                  type="submit"
                  variant="contained"
                >
                  Verify OTP
                </LoadingButton>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default DummyModal;
