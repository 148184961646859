import {
  // applyMiddleware,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // You can choose different storage options based on your needs
import thunk from "redux-thunk";

import userSlice from "../features/userSlice";
import blogSlice from "../features/blogSlice";
import wishlistSlice from "../features/wishlistSlice";
import addressSlice from "../features/addressSlice";
import cartSlice from "../features/cartSlice";
import alertSlice from "../alertSlice";
import filterSlice from "../features/filterSlice";
import couponSlice from "../features/couponSlice";
import contactSlice from "../features/contactSlice";
import loginSlice from "../loginSlice";
import confirmModalSlice from "../confirmModalSlice";
import orderSlice from "../features/orderSlice";
import homeSlice from "../features/homeSlice";
import companySlice from "../features/companySlice";
import pincodeSlice from "../features/pincodeSlice";
import productSlice from "../features/productSlice";
import drawerSlice from "../drawerSlice";
import notificationSlice from "../features/notificationSlice";
import affiliateSlice from "../features/affiliateSlice";
import memberSlice from "../features/memberSlice";
import assessmentSlice from "../features/assessmentSlice";
import walletSlice from "../features/walletSlice";
import reviewSlice from "../features/reviewSlice";
import diseaseSlice from "../features/diseaseSlice";
import appointmentSlice from "../features/appointmentSlice";
import navbarSlice from "../features/navbarSlice";
import LeftDrawerSlice from "../features/leftDrawerSlice";
// import partnerSlice from "../features/partnerSlice";
import becomePartnerSlice from "../features/becomePartnerSlice";
import partnerSlice from "../features/partnerSlice";

const persistConfig = {
  key: "root", // key is the key in the storage that will be used to store the data
  storage, // the storage engine you are using (imported from redux-persist)
  whitelist: ["isHome", "isAssessment", "isDisease"], // List the names of slices you want to persist, in this case, only "isHomes" will be persisted
};
const rootReducer = combineReducers({
  isAlert: alertSlice,
  isLogin: loginSlice,
  isDrawer: drawerSlice,
  isConfirmModal: confirmModalSlice,
  isUser: userSlice,
  isBlogs: blogSlice,
  isWishlist: wishlistSlice,
  isAddress: addressSlice,
  isCart: cartSlice,
  isFilters: filterSlice,
  isCoupon: couponSlice,
  isContact: contactSlice,
  isOrder: orderSlice,
  isHome: homeSlice,
  isCompany: companySlice,
  isPincode: pincodeSlice,
  isProduct: productSlice,
  isNotification: notificationSlice,
  isAffiliate: affiliateSlice,
  isMember: memberSlice,
  isAssessment: assessmentSlice,
  isWallet: walletSlice,
  isReview: reviewSlice,
  isDisease: diseaseSlice,
  isAppointment: appointmentSlice,
  isNavbar: navbarSlice,
  isLeftDrawerByShubham: LeftDrawerSlice,
  isPartner: becomePartnerSlice,
  isPartnerShip: partnerSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});

const persistStores = persistStore(Store);

export { Store, persistStores };
// export default Store;3
