import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import ExploreIcon from "@mui/icons-material/Explore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { useSelector } from "react-redux";

const MobileFooter = () => {
  const [value, setValue] = React.useState(0);
  const getCart = useSelector((state) => state?.isCart?.getCart);
  const ref = React.useRef(null);
  const navigate = useNavigate();

  return (
    <Box sx={{ pb: 7, display: { xs: "block", md: "none" } }} ref={ref}>
      <CssBaseline />
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 5,
        }}
        elevation={5}
      >
        <BottomNavigation
          sx={{
            backgroundColor: "primary.main",
            height: { xs: "4rem" },
            p: 0.5,
            "& .Mui-selected": {
              color:
                window.location.pathname.includes("/MobileProfilePage") ||
                window.location.pathname.includes("/blog") ||
                window.location.pathname.includes("/cartCheckOutPage") ||
                window.location.pathname.includes("/explore") ||
                window.location.pathname === "/"
                  ? "yellow !important"
                  : "white !important",
            },
          }}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            onClick={() => navigate("/")}
            label="Home"
            icon={<HomeIcon fontSize="small" />}
          />

          <BottomNavigationAction
            onClick={() => navigate("/explore")}
            label="Explore"
            icon={<ExploreIcon fontSize="small" />}
          />
          <BottomNavigationAction
            onClick={() => navigate("/cartCheckOutPage")}
            label="Cart"
            icon={
              <Badge badgeContent={getCart?.data?.length} color="secondary">
                <ShoppingCartIcon fontSize="small" />
              </Badge>
            }
          />
          <BottomNavigationAction
            onClick={() => navigate("/blog")}
            label="Blog"
            icon={<BorderColorIcon fontSize="small" />}
          />
          <BottomNavigationAction
            onClick={() => navigate("/MobileProfilePage")}
            label="Profile"
            icon={<AccountCircleOutlinedIcon fontSize="small" />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default MobileFooter;
