import {
  Box,
  List,
  ListItem,
  Container,
  Typography,
  Skeleton,
  Divider,
  Grid,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisease,
  handleDiseaseId,
} from "../../../Redux/features/diseaseSlice";
import {
  getNavbarTitle,
  // getSubCategories,
  // getChildCategories,
  getParentCategories,
  handleChild,
  handleSubCategory,
  getAllCategorySequence,
} from "../../../Redux/features/navbarSlice";

const SkeletonListItem = () => {
  return (
    <ListItem className="navtextHover">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={150}
          height={35}
        />
        <Skeleton variant="circular" width={25} height={25} animation="wave" />
        {/* <Skeleton variant="text" width={120} height={20} /> */}
        {/* <ArrowForwardIosIcon fontSize="1.2rem" /> */}
      </Box>
    </ListItem>
  );
};
const NavbarSecond = () => {
  const [newChild, setNewChild] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const pathName = window.location.pathname;
  const { allDisease } = useSelector((state) => state?.isDisease);
  const { navbarTitle, subTitle, parentTitle, isLoading } = useSelector(
    (state) => state?.isNavbar
  );
  const [navArrowIndexShow, setNavArrowIndexShow] = useState(0);

  // navbar header state
  const [navHeaderState, setNavHeaderState] = useState({
    nav1: false,
    nav2: false,
    nav3: false,
    nav4: false,
    nav5: false,
    nav6: false,
  });

  // function for navbar list data on hover
  const handleMouseNavHeader = (tab) => {
    if (tab === "nav1") {
      setNavHeaderState((pre) => ({
        ...pre,
        nav1: true,
      }));
    } else if (tab === "nav2") {
      setNavHeaderState((pre) => ({
        ...pre,
        nav2: true,
      }));
    } else if (tab === "nav3") {
      setNavHeaderState((pre) => ({
        ...pre,
        nav3: true,
      }));
    } else if (tab === "nav4") {
      setNavHeaderState((pre) => ({
        ...pre,
        nav4: true,
      }));
    } else if (tab === "nav5") {
      setNavHeaderState((pre) => ({
        ...pre,
        nav5: true,
      }));
    } else if (tab === "nav6") {
      setNavHeaderState((pre) => ({
        ...pre,
        nav6: true,
      }));
    }
  };

  // function for navbar header list close
  const handleMouseLeaveNavHeader = () => {
    setNavHeaderState((pre) => ({
      ...pre,
      nav1: false,
      nav2: false,
      nav3: false,
      nav4: false,
      nav5: false,
      nav6: false,
    }));
  };

  // getting all diseases
  useEffect(() => {
    dispatch(
      getDisease({
        url: `${process.env.REACT_APP_API}/dicease/getAllDicease?disable=false`,
      })
    );
  }, []);

  // getting all navbar title
  useEffect(() => {
    dispatch(
      getNavbarTitle({
        url: `${process.env.REACT_APP_API}/headerSection`,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(handleChild(newChild));
  }, [newChild]);

  // getting all parent , sub and child category at start at zero index id
  // useEffect(() => {
  //   dispatch(
  //     getParentCategories({
  //       url: `${process.env.REACT_APP_API}/getAllPcategory?disable=false`,
  //     })
  //   ).then((res) => {
  //     if (res?.payload) {
  //       dispatch(
  //         getSubCategories({
  //           url: `${
  //             process.env.REACT_APP_API
  //           }/getAllCategoryWithSubAndChildCategory?pCategory=${
  //             res?.payload?.data?.at(0)?._id
  //           }&dis?`,
  //         })
  //       ).then((data) => {
  //         if (data?.payload) {
  //           dispatch(
  //             getChildCategories({
  //               url: `${
  //                 process.env.REACT_APP_API
  //               }/getAllCategoryWithSubAndChildCategory?pCategory=${
  //                 data?.payload?.data?.at(0)?._id
  //               }&disable=false`,
  //             })
  //           );
  //         }
  //       });
  //     }
  //   });
  // }, []);

  useEffect(() => {
    dispatch(
      getParentCategories({
        url: `${process.env.REACT_APP_API}/getAllPcategory?disable=false`,
      })
    );
    dispatch(
      getAllCategorySequence({
        url: `${process.env.REACT_APP_API}/getCategoryBySequence`,
      })
    ).then((res) => {
      if (res?.payload) {
        const subCate = [];
        const newA = res?.payload?.data?.at(0)?.children;
        subCate?.push(...newA);
        dispatch(handleSubCategory(subCate));
      }
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "3rem",
          backgroundColor: "primary.main",
          position: "relative",
          color: "white.main",
        }}
      >
        <Container
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "3rem",
          }}
        >
          <NavLink
            to="/"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#f7f965" : "#ffffff",
            })}
          >
            <Typography
              className="textHover "
              variant="h6"
              fontSize={16}
              sx={{ fontWeight: "lighter" }}
            >
              Home
            </Typography>
          </NavLink>
          <Box
            onMouseLeave={() => {
              handleMouseLeaveNavHeader();
            }}
          >
            <Typography
              className="textHover "
              variant="h6"
              fontSize={16}
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "lighter",
                textDecoration: "none",
                height: "3rem",
              }}
              onMouseEnter={() => {
                handleMouseNavHeader("nav5");
              }}
            >
              Concerned By Disease
            </Typography>
            {navHeaderState?.nav5 && (
              <>
                <Typography
                  component={"span"}
                  sx={{
                    position: "absolute",
                    top: 34,
                    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                    height: "1rem",
                    width: "1.5rem",
                    bgcolor: "white.main",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    zIndex: 10,
                    ml: "0.8rem",
                    // mt: "0.5rem",
                    // border: "2px solid red",
                  }}
                ></Typography>
                <Box
                  // onMouseLeave={() => {
                  //   handleMouseLeaveNavHeader();
                  // }}
                  sx={{
                    position: "absolute",
                    display: "flex",
                    top: 48,
                    left: "10%",
                    right: "10%",
                    height: "28.5rem",
                    overflowX: "scroll",
                    minWidth: "7rem",
                    scrollBehavior: "smooth",
                    "&::-webkit-scrollbar": {
                      display: "hidden",
                      width: "0px",
                    },
                    py: 1,
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    backgroundColor: "white.main",
                    borderRadius: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      overflow: "auto",
                      display: "flex",
                      scrollBehavior: "smooth",
                    }}
                  >
                    <List
                      sx={{
                        backgroundColor: "white.main",
                        color: "black.main",
                        py: 0,
                        minWidth: "15rem",
                      }}
                    >
                      {(allDisease &&
                        allDisease?.length > 0 &&
                        allDisease?.slice(0, 10)?.map((ele, i) => (
                          <>
                            <ListItem
                              key={i}
                              className="navtext"
                              sx={{ m: 0, py: 0.6 }}
                              onClick={() => {
                                dispatch(handleDiseaseId(ele?._id));
                                navigate("/Diseases");
                                handleMouseLeaveNavHeader();
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    py: 0.5,
                                  }}
                                >
                                  {ele?.diceaseName}
                                </Typography>
                              </Box>
                            </ListItem>
                            <Divider
                              sx={{ pr: 2 }}
                              variant="inset"
                              component="li"
                            />
                          </>
                        ))) || (
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            p: 1,
                            fontWeight: 500,
                          }}
                        >
                          Diseases not available
                        </Typography>
                      )}
                    </List>
                    {allDisease && allDisease?.length > 0 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 10 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 10 &&
                          allDisease?.slice(10, 20)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 10 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 20 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 20 &&
                          allDisease?.slice(20, 30)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 20 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 30 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 30 &&
                          allDisease?.slice(30, 40)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 30 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 40 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 40 &&
                          allDisease?.slice(40, 50)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 40 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 50 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 50 &&
                          allDisease?.slice(50, 60)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 50 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 60 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 60 &&
                          allDisease?.slice(60, 70)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 60 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 70 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 70 &&
                          allDisease?.slice(70, 80)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 70 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 80 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 80 &&
                          allDisease?.slice(80, 90)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 80 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 90 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 90 &&
                          allDisease?.slice(90, 100)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 90 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 100 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 100 &&
                          allDisease?.slice(100, 110)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 100 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 110 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 110 &&
                          allDisease?.slice(110, 120)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 110 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 120 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 120 &&
                          allDisease?.slice(120, 130)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 120 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 130 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 130 &&
                          allDisease?.slice(130, 140)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 130 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 140 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 140 &&
                          allDisease?.slice(140, 150)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 140 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 150 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 150 &&
                          allDisease?.slice(150, 160)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 150 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 160 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 160 &&
                          allDisease?.slice(160, 170)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 160 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 170 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 170 &&
                          allDisease?.slice(170, 180)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 170 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 180 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 180 &&
                          allDisease?.slice(180, 190)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                    {allDisease && allDisease?.length > 180 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {allDisease && allDisease?.length > 190 && (
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                          minWidth: "15rem",
                        }}
                      >
                        {allDisease &&
                          allDisease?.length > 190 &&
                          allDisease?.slice(190, 200)?.map((ele, i) => (
                            <>
                              <ListItem
                                key={i}
                                className="navtext"
                                sx={{ m: 0, py: 0.6 }}
                                onClick={() => {
                                  dispatch(handleDiseaseId(ele?._id));
                                  navigate("/Diseases");
                                  handleMouseLeaveNavHeader();
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      wordBreak: "break-all",
                                      wordWrap: "break-word",
                                      py: 0.5,
                                    }}
                                  >
                                    {ele?.diceaseName}
                                  </Typography>
                                </Box>
                              </ListItem>
                              <Divider
                                sx={{ pr: 2 }}
                                variant="inset"
                                component="li"
                              />
                            </>
                          ))}
                      </List>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>

          {navbarTitle &&
            navbarTitle?.map((ele, i) => (
              <Box
                key={i}
                onMouseEnter={() => {
                  if (i === 3) {
                    handleMouseNavHeader("nav4");
                  } else if (i === 1) {
                    handleMouseNavHeader("nav2");
                  } else if (i === 0) {
                    handleMouseNavHeader("nav1");
                    dispatch(
                      getAllCategorySequence({
                        url: `${process.env.REACT_APP_API}/getCategoryBySequence`,
                      })
                    ).then((res) => {
                      if (res?.payload) {
                        const subCate = [];
                        const newA = res?.payload?.data?.at(0)?.children;
                        subCate?.push(...newA);
                        dispatch(handleSubCategory(subCate));
                      }
                    });
                  }
                }}
                onMouseLeave={() => {
                  handleMouseLeaveNavHeader();
                }}
                className="textHover "
                variant="h6"
                fontSize={16}
                sx={{
                  fontWeight: "lighter",
                  height: "3rem",
                  display: "flex",
                  alignItems: "center",
                  color:
                    window.location.pathname.includes("/Diseases") &&
                    ele?.navNo === "nav5" &&
                    "#f7f965",
                }}
              >
                {ele?.name}

                {navHeaderState?.nav1 && i === 0 && (
                  <>
                    <Typography
                      component={"span"}
                      sx={{
                        position: "absolute",
                        top: 34,
                        clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                        height: "1rem",
                        width: "1.5rem",
                        bgcolor: "white.main",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        zIndex: 10,
                        ml: "0.8rem",
                      }}
                    ></Typography>
                    <Box
                      onMouseLeave={() => {
                        setNavArrowIndexShow(0);
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        top: 48,
                        left: "8%",
                        right: "8%",
                        cursor: "default",
                      }}
                    >
                      <Box
                        sx={{
                          height: "28rem",
                          overflowY: "scroll",
                          width: "20rem",
                          scrollBehavior: "smooth",
                          "&::-webkit-scrollbar": {
                            display: "hidden",
                            width: "0px",
                          },
                          py: 1,
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          backgroundColor: "#f2f8f8",
                        }}
                      >
                        <List
                          sx={{
                            backgroundColor: "color: #e6ffff",
                            color: "black.main",
                            py: 0,
                          }}
                        >
                          {isLoading
                            ? Array.from(Array(10))?.map((v, i) => (
                                <SkeletonListItem key={i} />
                              ))
                            : parentTitle?.map((ele, i) => (
                                <Fragment key={i}>
                                  <ListItem
                                    key={i}
                                    className="navtextHover"
                                    sx={{
                                      backgroundColor:
                                        i === navArrowIndexShow && "white.main",
                                      color:
                                        i === navArrowIndexShow && "#00b5b7",
                                    }}
                                  >
                                    <Box
                                      onMouseEnter={() => {
                                        // setNavArrowIndexShow2(0);
                                        setNavArrowIndexShow(i);
                                        dispatch(
                                          getAllCategorySequence({
                                            url: `${process.env.REACT_APP_API}/getCategoryBySequence`,
                                          })
                                        ).then((res) => {
                                          if (res?.payload) {
                                            const subCate = [];
                                            const newA =
                                              res?.payload?.data?.at(
                                                i
                                              )?.children;
                                            subCate?.push(...newA);
                                            dispatch(
                                              handleSubCategory(subCate)
                                            );
                                            let subsAllChild = [];
                                            for (
                                              i = 0;
                                              i < subCate?.length;
                                              i++
                                            ) {
                                              const ch =
                                                subCate?.at(i)?.children;
                                              subsAllChild?.push(...ch);
                                            }
                                            setNewChild(subsAllChild);
                                          }
                                        });
                                      }}
                                      // onMouseLeave={() => {
                                      //   dispatch(handleChild([]));
                                      //   dispatch(handleSubCategory([]));
                                      // }}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.95rem",
                                          fontWeight: 500,
                                          textTransform: "capitalize",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "1",
                                          WebkitBoxOrient: "vertical",
                                          wordBreak: "break-all",
                                          wordWrap: "break-word",
                                          py: 0.5,
                                          pl: 1.5,
                                        }}
                                      >
                                        {ele?.name}
                                      </Typography>
                                      <ArrowForwardIosIcon fontSize="1.2rem" />
                                    </Box>
                                  </ListItem>{" "}
                                  <Divider sx={{ pr: 2 }} component="li" />
                                </Fragment>
                              ))}
                        </List>
                      </Box>
                      <Box
                        sx={{
                          height: "28rem",
                          overflowY: "scroll",
                          // width: "40rem",
                          width: "100%",
                          scrollBehavior: "smooth",
                          "&::-webkit-scrollbar": {
                            display: "hidden",
                            width: "0px",
                          },
                          py: 1,
                          backgroundColor: "white.main",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          color: "black.main",
                        }}
                      >
                        <Box sx={{ display: "flex", width: "100%", px: 1 }}>
                          <Grid sx={12} container spacing={10}>
                            {subTitle?.map((ele, i) => (
                              <Grid item key={i} md={3}>
                                <Typography
                                  // className="navtextHover"
                                  sx={{
                                    pl: 2,
                                    pt: 1.5,
                                    fontWeight: 500,
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {/* Sub category */}
                                  {ele?.name}
                                </Typography>

                                <List>
                                  {ele?.children?.map((item, j) => (
                                    <ListItem key={j} sx={{ py: 0.5 }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          // border:"2px solid red"
                                        }}
                                      >
                                        <Typography
                                          onClick={() => {
                                            navigate(`/filter/${item?._id}`, {
                                              state: { category: item?._id },
                                            });
                                            handleMouseLeaveNavHeader();
                                          }}
                                          className="navtextHover"
                                          sx={{
                                            fontSize: "0.9rem",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical",
                                            wordBreak: "break-all",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {/* child name */}
                                          {item?.name}
                                        </Typography>
                                      </Box>
                                    </ListItem>
                                  ))}
                                </List>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                {navHeaderState?.nav2 && i === 1 && (
                  <>
                    <Typography
                      component={"span"}
                      sx={{
                        position: "absolute",
                        top: 34,
                        clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                        height: "1rem",
                        width: "1.5rem",
                        bgcolor: "white.main",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        zIndex: 10,
                        ml: "0.8rem",
                      }}
                    ></Typography>
                    <Box
                      onMouseLeave={() => {
                        handleMouseLeaveNavHeader();
                      }}
                      sx={{
                        position: "absolute",
                        top: 48,
                        maxHeight: "30rem",
                        overflowY: "scroll",
                        minWidth: "7rem",
                        scrollBehavior: "smooth",
                        "&::-webkit-scrollbar": {
                          display: "hidden",
                          width: "0px",
                        },
                        py: 1,
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        backgroundColor: "white.main",
                        borderRadius: "0.5rem",
                        cursor: "default",
                      }}
                    >
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                        }}
                      >
                        {ele?.navigatorArr?.map((ele, i) => (
                          <>
                            <ListItem key={i} className="navtext">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    py: 0.5,
                                  }}
                                >
                                  {ele}
                                </Typography>
                              </Box>
                            </ListItem>
                            <Divider sx={{ pr: 2 }} component="li" />
                          </>
                        ))}
                      </List>
                    </Box>
                  </>
                )}
                {navHeaderState?.nav4 && i === 3 && (
                  <>
                    <Typography
                      component={"span"}
                      sx={{
                        position: "absolute",
                        top: 34,
                        clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                        height: "1rem",
                        width: "1.5rem",
                        bgcolor: "white.main",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        zIndex: 10,
                        ml: "0.8rem",
                      }}
                    ></Typography>
                    <Box
                      onMouseLeave={() => {
                        handleMouseLeaveNavHeader();
                      }}
                      sx={{
                        position: "absolute",
                        top: 48,
                        maxHeight: "30rem",
                        overflowY: "scroll",
                        minWidth: "7rem",
                        scrollBehavior: "smooth",
                        "&::-webkit-scrollbar": {
                          display: "hidden",
                          width: "0px",
                        },
                        py: 1,
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        backgroundColor: "white.main",
                        borderRadius: "0.5rem",
                        cursor: "default",
                      }}
                    >
                      <List
                        sx={{
                          backgroundColor: "white.main",
                          color: "black.main",
                          py: 0,
                        }}
                      >
                        {ele?.navigator?.map((ele, i) => (
                          <>
                            <ListItem
                              key={i}
                              className="navtext"
                              onClick={() => {
                                if (ele === "Self Assesment") {
                                  navigate("/selfAssessment");
                                } else if (ele === "Ask expert") {
                                  navigate("/consultation");
                                }
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    py: 0.5,
                                  }}
                                >
                                  {ele}
                                </Typography>
                              </Box>
                            </ListItem>
                            <Divider sx={{ pr: 2 }} component="li" />
                          </>
                        ))}
                      </List>
                    </Box>
                  </>
                )}
              </Box>
            ))}

          <NavLink
            to="/explore"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#f7f965" : "#ffffff",
            })}
          >
            <Typography
              className="textHover "
              variant="h6"
              fontSize={16}
              sx={{ fontWeight: "lighter" }}
            >
              Explore
            </Typography>
          </NavLink>
          <NavLink
            to="/blog"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#f7f965" : "#ffffff",
            })}
          >
            <Typography
              className="textHover "
              variant="h6"
              fontSize={16}
              sx={{ fontWeight: "lighter" }}
            >
              Blog
            </Typography>
          </NavLink>
        </Container>
      </Box>
    </>
  );
};

export default NavbarSecond;
